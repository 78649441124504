import * as React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../src/theme';
import '../../src/css/global.css'

export default function TopLayout(props) {
    return ( <
        React.Fragment >
        <
        Helmet >
        <
        meta name = "viewport"
        content = "initial-scale=1, width=device-width" / >
        <
        link href = "https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
        rel = "stylesheet" / >
        <
        link rel = "preconnect"
        href = "https://fonts.googleapis.com" / >
        <
        link rel = "preconnect"
        href = "https://fonts.gstatic.com"
        crossorigin / >
        <
        link href = "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel = "stylesheet" / >
        <
        /Helmet> <
        ThemeProvider theme = { theme } >
        <
        CssBaseline / > { props.children } <
        /ThemeProvider> < /
        React.Fragment >
    );
}

TopLayout.propTypes = {
    children: PropTypes.node,
};