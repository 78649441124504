import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary : "#FFFFFF",
    }
  },
  components : {
    MuiPaper : {
      styleOverrides : {
        root : {
          backgroundColor: '#1d1d1d'
        }
      }
    },
  },
  typography: {
    fontFamily : "Poppins",
    
  }
});

export default theme;